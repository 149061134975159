import React, { FC } from 'react';
import clsx from 'clsx';
import style from './HomePage.module.scss';
import PageWrap from '../PageWrap/PageWrap';
import Header from '../../components/Header/Header';
// import floatingIm from '../../vendor/images/cogs_result.svg';
import floatingIm from '../../vendor/images/handmade-1.png';
import image3 from '../../vendor/images/gloves.jpg';

const image1 = 'https://images.unsplash.com/photo-1517677208171-0bc6725a3e60?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80';
const image2 = 'https://images.unsplash.com/photo-1515611309071-6cd7e12cace9?q=80&w=1872&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

//   <div className={style.floatingImg} style={{ backgroundImage: floatingIm }} />

const HomePage:FC = () => {
  const banner = (
    <div className={style.banner}>
      <Header />
      <div className={style.overlay}>
        <section className={style.textpart}>
          <h1 className={style.banner__title}>Изделия ручной работы</h1>
          <p className={style.banner__text}>Эксклюзивные и популярные вязаные модели</p>
          <button className={style.banner__button} type="button">Перейти в каталог</button>
        </section>
        <section className={style.imagespart}>
          <div className={clsx(style.banner_productimg, style.floatfirst)}><img src={image1} alt="first preview" className={clsx(style.previewImg, style.previewImgFirst)} /></div>
          <div className={clsx(style.banner_productimg, style.floatsecond)}><img src={image2} alt="first preview" className={clsx(style.previewImg, style.previewImgSec)} /></div>
          <div className={clsx(style.banner_productimg, style.floatthird)}><img src={image3} alt="first preview" className={clsx(style.previewImg, style.previewImgThird)} /></div>
          <p>sdsdfgsd</p>
        </section>
      </div>
      <img alt="decor" className={style.floatingImg} src={floatingIm} />
    </div>
  );
  const preview = (
    <section className={clsx(style.preview, style.section)}>
      <h2 className={style.subtitle}>Наши преимущества: </h2>
      <ul className={style.benefitsList}>
        <li className={style.benefit}><p>image and text</p></li>
        <li className={clsx(style.benefit, style.secondBen)}><p>image and text</p></li>
        <li className={clsx(style.benefit, style.thirdBen)}><p>image and text</p></li>
      </ul>
    </section>

  );

  const colorScheme = 'violet'; // change this variable in redux. make buttons to change colors

  const themesAll = {
    main: style.thememain,
    violet: style.themeviolet,
    green: style.themegreen,
  };

  return (
    <div className={style.themes}>
      <div
        className={clsx(
          style.themeBox,
          themesAll[colorScheme],
        )}
      >
        <PageWrap>
          {banner}
          {preview}
        </PageWrap>
      </div>

    </div>
  );
};

export default HomePage;


import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import style from './App.module.scss';
import Header from '../Header/Header';
import HomePage from '../../pages/HomePage/HomePage';

const App:FC = () => (
  <Routes>
    <Route path='/' element={<HomePage />} />
  </Routes>

);

export default App;

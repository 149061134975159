import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import style from './Header.module.scss';
import mainLogo from '../../vendor/images/logo-purple2.png';

const Header:FC = () => {
  const headerMenu = [{
    title: 'Главная',
    linkTo: '/',

  },
  {
    title: 'Каталог',
    linkTo: '/shop',

  },
  {
    title: 'О нас',
    linkTo: '/about',

  }];

  return (
    <div className={style.header}>
     <div className={style.logoAndMenu}>
      <Link to="/" className={style.logoLink}><img alt="логотип на странице" src={mainLogo} className={style.logo} /></Link>
      <ul className={style.menu}>
        {headerMenu.map((item) => (
          <li key={item.title} className={style.menu__item}>
            <Link to={item.linkTo} className={style.menu__link}>{item.title}</Link>
          </li>
        ))}
      </ul>
      </div>
      <Link to="/" className={style.header__button}>выбрать</Link>
    </div>
  );
};

export default Header;

import React, { FC } from 'react';
import style from './PageWrap.module.scss';
import Header from '../../components/Header/Header';

const PageWrap:FC<{ children: any; }> = ({ children }) => (
  <div className={style.page}>
    <div className={style.container}>
      <div className={style.pageContent}>
        {children}
      </div>
    </div>

  </div>
);

export default PageWrap;

